<template>
  <div class="detalhes-pix">
    <div class="item">
      <div>
        <svg
          width="25"
          height="27"
          viewBox="0 0 25 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.7778 1L1 16H12.5L11.2222 26L24 11H12.5L13.7778 1Z"
            :fill="main_color"
            :stroke="main_color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h4>{{ $t("checkout.pagamento.metodos.pix.pix_info1.titulo") }}</h4>
      <p>{{ $t("checkout.pagamento.metodos.pix.pix_info1.texto") }}</p>
    </div>
    <div class="item">
      <div>
        <svg
          width="20"
          height="27"
          viewBox="0 0 20 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4286 1H3.57143C2.15127 1 1 2.11929 1 3.5V23.5C1 24.8807 2.15127 26 3.57143 26H16.4286C17.8487 26 19 24.8807 19 23.5V3.5C19 2.11929 17.8487 1 16.4286 1Z"
            :fill="main_color"
          />
          <path
            d="M10 21H10.0129M3.57143 1H16.4286C17.8487 1 19 2.11929 19 3.5V23.5C19 24.8807 17.8487 26 16.4286 26H3.57143C2.15127 26 1 24.8807 1 23.5V3.5C1 2.11929 2.15127 1 3.57143 1Z"
            :stroke="main_color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h4>{{ $t("checkout.pagamento.metodos.pix.pix_info2.titulo") }}</h4>
      <p>{{ $t("checkout.pagamento.metodos.pix.pix_info2.texto") }}</p>
    </div>
    <div class="item">
      <div>
        <svg
          width="22"
          height="27"
          viewBox="0 0 22 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 26C11 26 21 21 21 13.5V4.75L11 1L1 4.75V13.5C1 21 11 26 11 26Z"
            :fill="main_color"
            :stroke="main_color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h4>{{ $t("checkout.pagamento.metodos.pix.pix_info3.titulo") }}</h4>
      <p>{{ $t("checkout.pagamento.metodos.pix.pix_info3.texto") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    main_color: {
      type: String,
      default: "#00E4A0"
    }
  }
}
</script>

<style scoped>
.detalhes-pix {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

h4 {
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0 10px 0;
}
p {
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
}

@media screen and (max-width: 769px) {
  .detalhes-pix {
    grid-template-columns: 1fr;
  }
}
</style>