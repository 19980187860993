import Rest from '@/services/Rest';

/**
 * @typedef {ClientStatisticsService}
 */
export default class ClientStatisticsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/client-statistics'
}
