<template>
  <div class="countdown" v-if="validade">
    <div class="time">
      <div class="time-item">
        <p>Este cupom expira em {{ hours }}h:{{ minutes }}m:{{ seconds }}s</p>
        <!-- <span>Dias</span> -->
        <!-- <p>{{ days }}</p> -->
      </div>
      <!-- <div class="time-item">
        <span>Horas</span>
        <p>{{ hours }}h:</p>
      </div>
      <div class="time-item">
        <span>Minutos</span>
        <p>{{ minutes }}m:</p>
      </div>
      <div class="time-item">
        <span>Segundos</span>
        <p>{{ seconds }}s</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      actualTime: moment().format("X"),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  props: {
    validade: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateTime() {
      this.actualTime = moment().format("X");
      setTimeout(() => {
        this.updateTime();
      }, 1000);
    },
    getDiffInSeconds() {
      return this.validade
        ? moment(this.validade).format("X") - this.actualTime
        : false;
    },
    compute() {
      const duration = moment.duration(this.getDiffInSeconds(), "seconds");
      this.days = duration.days() > 0 ? duration.days() : 0;
      this.hours = duration.hours() > 0 ? duration.hours() : 0;
      this.minutes =
        duration.minutes() > 0
          ? duration.minutes() < 10
            ? `0${duration.minutes()}`
            : duration.minutes()
          : 0;
      this.seconds =
        duration.seconds() > 0
          ? duration.seconds() < 10
            ? `0${duration.seconds()}`
            : duration.seconds()
          : 0;

      if (this.days > 0) {
        this.hours += 24 * this.days;
        this.hours = this.hours < 10 ? `0${this.hours}` : this.hours;
      }
    },
  },
  created() {
    this.compute();
    this.updateTime();
  },
  watch: {
    actualTime() {
      this.compute();
    },
  },
};
</script>

<style scoped>
.timer span {
  font-size: 13px;
  font-weight: 600;
  display: block;
}
.time {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 40px; */
  margin: 10px 0;
  width: 100%;
}

.time-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  position: relative;
}
.time-item + .time-item::before {
  /* content: ":"; */
  position: absolute;
  left: -10px;
  top: 38%;
  color: var(--main-color);
}
.time-item span {
  font-size: 12px;
  font-weight: normal;
}
.time-item p {
  font-weight: 600;
  /* font-size: 14px; */
  font-size: 12px;
  color: var(--main-color);
  background-color: var(--transparent);
  padding: 10px 15px;
  text-align: left;
  white-space: normal;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 5px;
}
</style>