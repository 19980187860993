<template>
  <div class="container-paises" :class="{ girar: paises }">
    <div @click="paises = !paises" class="item-pais pais-selecionado">
      <img :src="require(`@/assets/locales/flag-${pais_code === 'GB' ? 'UK' : pais_code}.png`)" />
      {{ paises ? $t("locales.close") : $t("locales.trocar") }}
    </div>
    <ul class="paises" data-anima="top" v-show="paises">
      <div class="container-pesquisa mt-3 mt-md-0">
        <div class="inputSearch">
          <img src="@/assets/img/icon/search.svg" class="Pointer search" />
          <input
            type="text"
            :placeholder="$t('locales.placeholder')"
            v-model="search"
            class="input-busca"
          />
        </div>
      </div>
      <template v-if="productType !== 'SUBSCRIPTION'">
        <div v-for="list in filteredItems" :key="list.sigla">
          <li class="item-pais" @click="changeCountry(list.sigla, list.pais)">
            <img :src="require(`@/assets/locales/flag-${list.sigla}.png`)" />
            {{ list.sub }} | {{ list.pais }}
          </li>
        </div>
      </template>

      <template v-else>
        <div v-for="list in filteredItemsCreditCard" :key="list.sigla">
          <li class="item-pais" @click="changeCountry(list.sigla, list.pais)">
            <img :src="require(`@/assets/locales/flag-${list.sigla}.png`)" />
            {{ list.sub }} | {{ list.pais }}
          </li>
        </div>
      </template>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "Locales",
  props: {
    productType: {
      required: false,
      type: String,
      default: ''
    },
    productSellerIsHeaven: {
      required: false,
      type: [Boolean, Number],
      default: false
    },
    countryGlobal: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      pais: "",
      paises: false,
      pais_nome: "BR | Português",
      pais_code: "BR",
      search: "",
      lists: [
        { sigla: "BR", sub: "Brazil", pais: "Brasil" },
        { sigla: "AR", sub: "Argentina", pais: "Argentina" },
        { sigla: "BO", sub: "Bolivia", pais: "Bolivia" },
        { sigla: "AU", sub: "Australia", pais: "Austrália" },
        { sigla: "CA", sub: "Canada", pais: "Canadá" },
        { sigla: "CL", sub: "Chile", pais: "Chile" },
        { sigla: "CO", sub: "Colombia", pais: "Colombia" },
        { sigla: "CR", sub: "Costa Rica", pais: "Costa Rica" },
        { sigla: "DE", sub: "Germany", pais: "Alemanha" },
        { sigla: "DO", sub: "Dominican Republic", pais: "República Dominicana" },
        { sigla: "EC", sub: "Equador", pais: "Equador" },
        { sigla: "ES", sub: "Spain", pais: "Espanha" },
        { sigla: "FR", sub: "France", pais: "França" },
        { sigla: "GT", sub: "Guatemala", pais: "Guatemala" },
        { sigla: "HN", sub: "Honduras", pais: "Honduras" },
        { sigla: "IT", sub: "Italy", pais: "Italia" },
        { sigla: "IL", sub: "Israel", pais: "Israel" },
        { sigla: "JP", sub: "Japan", pais: "Japão" },
        { sigla: "MY", sub: "Malaysia", pais: "Malaysia" },
        { sigla: "MX", sub: "Mexico", pais: "México" },
        { sigla: "PA", sub: "Panama", pais: "Panamá" },
        { sigla: "PE", sub: "Perú - Piruw", pais: "Peru" },
        { sigla: "PR", sub: "Puerto Rico", pais: "Porto Rico" },
        { sigla: "PT", sub: "Portugal", pais: "Portugal" },
        { sigla: "PY", sub: "Paraguay", pais: "Paraguai" },
        { sigla: "UK", sub: "United Kingdom", pais: "Reino Unido" },
        { sigla: "US", sub: "United States", pais: "Estados Unidos" },
        { sigla: "UY", sub: "Uruguai", pais: "Uruguai" },
        { sigla: "OUTROS", sub: "Others", pais: "Outros" },
      ],
      listsCreditCard: [
        { sigla: "BR", sub: "Brazil", pais: "Brasil" },
        { sigla: "CL", sub: "Chile", pais: "Chile" },
        { sigla: "CO", sub: "Colombia", pais: "Colombia" },
        { sigla: "CR", sub: "Costa Rica", pais: "Costa Rica"},
        { sigla: "DE", sub: "Germany", pais: "Alemanha" },
        { sigla: "DO", sub: "República Dominicana", pais: "Republica Dominicana"},
        { sigla: "EC", sub: "Equador", pais: "Equador"},
        { sigla: "ES", sub: "Spain", pais: "Espanha" },
        { sigla: "FR", sub: "France", pais: "França" },
        { sigla: "GT", sub: "Guatemala", pais: "Guatemala" },
        { sigla: "HN", sub: "Honduras", pais: "Honduras" },
        { sigla: "IT", sub: "Italy", pais: "Italia" },
        { sigla: "JP", sub: "Japan", pais: "Japão" },
        { sigla: "MY", sub: "Malaysia", pais: "Malaysia" },
        { sigla: "MX", sub: "Mexico", pais: "México" },
        { sigla: "PA", sub: "Panama", pais: "Panamá" },
        { sigla: "PE", sub: "Perú - Piruw", pais: "Peru" },
        { sigla: "PR", sub: "Puerto Rico", pais: "Porto Rico" },
        { sigla: "PT", sub: "Portugal", pais: "Portugal" },
        { sigla: "UK", sub: "United Kingdom", pais: "Reino Unido" },
        { sigla: "US", sub: "United States", pais: "Estados Unidos" },
        { sigla: "UY", sub: "Uruguai", pais: "Uruguai" },
        { sigla: "OUTROS", sub: "Others", pais: "Outros" },
      ],
      paypalRemoveCountry: ['AR','BO','AU','CA','GT','HN','PA','PY'],
      removeCountryIfNotDlocal: ['EC', 'CR', 'DO', 'UY']
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    countryFlags() {
      return this.$store.getters["getCountryFlags"]
    },
    filteredItems() {
      let countryList = this.lists
  
      if (! this.countryFlags) {
        countryList = countryList.filter(country => !this.removeCountryIfNotDlocal.includes(country.sigla));
      }
     
      if(
        this.isHeaven ||
        (!this.isHeaven && this.productSellerIsHeaven)
      ) {
        let array = []
        countryList.forEach(x => {
          if(!this.paypalRemoveCountry.some(sigla => sigla === x.sigla)) {
            array.push(x)
          }
        })
        countryList = array
      }
      return !this.search.length
        ? countryList
        : countryList.filter(
            (item) =>
              item.pais
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sub
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sigla
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
          );
    },
    filteredItemsCreditCard() {
      let countryList = this.listsCreditCard
      
      if (! this.countryFlags) {
        countryList = countryList.filter(country => !this.removeCountryIfNotDlocal.includes(country.sigla));
      }
      
      if(
        this.isHeaven ||
        (!this.isHeaven && this.productSellerIsHeaven)
      ) {
        let array = []
        countryList.forEach(x => {
          if(!this.paypalRemoveCountry.some(sigla => sigla === x.sigla)) {
            array.push(x)
          }
        })
        countryList = array 
      }
      return !this.search.length
        ? countryList
        : countryList.filter(
            (item) =>
              item.pais
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sub
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sigla
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
          );
    },
  },
  methods: {
    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = { locale: code };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = !this.paises;
    },
    selectCountry(locale) {
      localStorage.setItem("location", locale);
      this.search = "";
      if (locale === "BR" || locale === "PT") {
        this.$i18n.locale = "pt";
      } else if (
        locale == "AR" ||
        locale == "FR" ||
        locale == "IT" ||
        locale == "IL" ||
        locale == "JP" ||
        locale == "MY" ||
        locale == "PR" ||
        locale == "PY" ||
        locale == "PA" ||
        locale == "BO" ||
        locale == "CR" ||
        locale == "DO" ||
        locale == "HN" ||
        locale == "CO" ||
        locale == "ES" ||
        locale == "GT" ||
        locale == "CL" ||
        locale == "EC" ||
        locale == "MX" ||
        locale == "PE" ||
        locale == "AR" ||
        locale == "UY" 
      ) {
        this.$i18n.locale = "es";
      } else if (
        locale == "UK" ||
        locale == "GB" ||
        locale == "US" ||
        locale == "AU" ||
        locale == "CA" ||
        locale == "DE" ||
        locale == "OUTROS"
      ) {
        this.$i18n.locale = "en";
      } else {
        // this.$i18n.locale = "en";
        EventBus.$emit("atualizaPais", { locale: "OUTROS" });
        this.$i18n.locale = "en";
      }
    },
  },
  created() {
    EventBus.$on("changeLocale", (data) => {
      localStorage.setItem("location", data.locale);
      if (data.locale == "BR") {
        this.selectCountry("BR");
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      } else if (
        data.locale == "AR" ||
        data.locale == "FR" ||
        data.locale == "IT" ||
        data.locale == "IL" ||
        data.locale == "JP" ||
        data.locale == "ML" ||
        data.locale == "PR" ||
        data.locale == "PY" ||
        data.locale == "PA" ||
        data.locale == "BO" ||
        data.locale == "CR" ||
        data.locale == "DO" ||
        data.locale == "HN" ||
        data.locale == "ES" ||
        data.locale == "GT" ||
        data.locale == "CO" ||
        data.locale == "CL" ||
        data.locale == "EC" ||
        data.locale == "MX" ||
        data.locale == "PE" ||
        data.locale == "UY" ||
        data.locale == "PT"
      ) {
        this.selectCountry(data.locale);
        let pais = this.productType !== 'SUBSCRIPTION'
          ? this.listsCreditCard.filter((item) => item.sigla === data.locale)
          : this.lists.filter((item) => item.sigla === data.locale);
        this.pais_nome = `${data.locale} | ${pais}`;
        this.pais_code = data.locale;
      } else if (
        data.locale == "UK" ||
        data.locale == "GB" ||
        data.locale == "US" ||
        data.locale == "AU" ||
        data.locale == "CA" ||
        data.locale == "DE" ||
        data.locale == "OUTROS"
      ) {
        this.selectCountry(data.locale);
        let pais = this.productType !== 'SUBSCRIPTION'
          ? this.listsCreditCard.filter((item) => item.sigla === data.locale)
          : this.lists.filter((item) => item.sigla === data.locale);
        this.pais_nome = `${data.locale} | ${pais}`;
        this.pais_code = data.locale;
      } else {
        // this.selectCountry("US");
        // this.pais_nome = "US | English";
        // this.pais_code = "US";
        this.selectCountry(data.locale);
        this.pais_nome = "Others | Outros";
        this.pais_code = "OUTROS";
      }
    });
  },
};
</script>

<style scoped>
/* // paises */
.container-paises {
  position: absolute;
  right: 0;
  z-index: 9999;
  width: 180px;
}

.input-busca {
  width: -webkit-fill-available;
  height: 40px !important;
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
  transition: 0.5s;
  margin-bottom: 15px;
}
.input-busca::placeholder {
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
  margin-top: 13px;
}
@media screen and (max-width: 1300px) {
  .pais-selecionado {
    margin-left: 30px !important;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.paises {
  background: var(--bg-checkout);
  width: max-content;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  left: -53px;
  height: 400px;
  overflow: auto;
}

.pais-selecionado {
  margin-left: 15px;
}
@media only screen and (min-width: 390px) {
  .pais-selecionado::after {
    content: "";
    background: url("../assets/img/icon/arrow-fill.svg") no-repeat center center;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0;
  }
  .girar .pais-selecionado::after {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
  .item-pais::before {
    content: "";
    width: 5px;
    height: 5px;
    background: transparent;
    position: absolute;
    left: -5px;
    border-radius: 50%;
  }
  .item-pais:hover::before {
    background: var(--main-color);
  }
}
@media only screen and (max-width: 390px) {
  /* .item-pais {
    justify-content: flex-end !important;
  } */
  .paises {
    left: -150px !important;
  }
}
.item-pais {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  padding: 7px 10px;
  /* font-weight: 600; */
  padding-right: 0;
  transition: 0.3s;
  color: var(--text-color);
  position: relative;
  margin-bottom: 5px;
}

.item-pais + .item-pais {
  margin-top: 10px;
}
.item-pais:hover {
  color: var(--main-color);
}

.item-pais img {
  width: 20px;
  height: 20px;
}
</style>