<template>
  <b-modal id="alert-modal" @hidden="closeModal" :title="product_name" @show="modalOpened" size="md">
    <b-container fluid>
      <h6 class="subtitulo">{{ $t("checkout.dados_pessoais.title_error") }}</h6>
      <!-- <h6 class="subtitulo">Algo deu errado</h6> -->
      <p class="paragrafo p-alert" v-html="message_error" v-if="has_code"></p>
      <p class="paragrafo p-alert" v-if="!has_code">
        {{ message_error }}
      </p>
    </b-container>
    <template v-slot:modal-footer="{}" class="d-flex">
      <b-button variant="info" class="mr-3 btn-modal" @click="closeModal"
        >{{ $t("checkout.dados_pessoais.btn_error") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {};
  },
  props: {
    product_name: {
      type: String,
      default: "",
    },
    message_error: {
      type: String,
      default: "",
    },
    has_code: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    modalOpened(){
      window.history.pushState(null, '', this.getResetLink());
    },
    closeModal() {
      window.location.href = this.getResetLink();
    },
    getResetLink(){
    const currentURL = new URL(window.location.href);
    const newSearchParams = new URLSearchParams();
    currentURL.searchParams.forEach((value, key) => {
        if (!key.startsWith('_rl_')) {
            newSearchParams.set(key, value);
        }
    });
    newSearchParams.set('_rl_' + Math.random().toString(36).substring(2, 8), Math.random().toString(36).substring(2, 8));
    currentURL.search = newSearchParams.toString();
    return currentURL.toString();
}

  },
};
</script>

<style>
.modal {
  overflow: auto;
}
.modal-content {
  padding: 20px 30px !important;
  animation: showTop 0.3s forwards !important;
}
.modal-content .modal-header {
  border-bottom: 1px solid #e4e4ec;
  border: none;
  border-radius: 0;
}
.modal-content .modal-header h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  max-width: 300px;
}
.modal-body {
  padding: 10px 0;
}
.col-auto {
  padding: 0;
}
.modal-content .modal-header .close {
  background: url("../assets/img/icon/fechar.svg") no-repeat center center;
  filter: invert(1);
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-footer {
  /* border-top: 1px solid #e4e4ec; */
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 10px;
}
/* novos */
.subtitulo {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.paragrafo.p-alert {
  font-size: 15px;
  line-height: 1.5;
  color: var(--text-color);
  font-weight: normal;
}
</style>