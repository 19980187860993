export function symbolCurrency(id) {
  let t = "";
  if (id) {
    switch (id) {
      case 13: {
        t = {symbol:"$", pais:"AR"};
        break;
      }
      case 12: {
        t = {symbol:"$", pais:"UY"};
        break;
      }
      case 10: {
        t = {symbol:"$", pais:"PB"};
        break;
      }
      case 9: {
        t = {symbol:"$", pais:"MX"};
        break;
      }
      case 6: {
        t = {symbol:"$", pais:"CL"};
        break;
      }
      case 5: {
        t = {symbol:"$", pais:"CO"};
        break;
      }
      case 4: {
        t = {symbol:"\u20ac", pais:"GB"};
        break;
      }
      case 3: {
        t = {symbol:"\u00a3", pais:"ES"};
        break;
      }
      case 2: {
        t = {symbol:"US$", pais:"US"};
        break;
      }
      case 1: {
        t = {symbol:"R$", pais:"BR"};
        break;
      }

      default: {
        t = id;
        break;
      }
    }
    return t;
  } else return id;
}