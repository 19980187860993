export function districtOptions(country) {
  if(country) {
    switch(country) {
      case 'BR': return ["AC","AL","AM","AP","BA","CE","DF","ES","GO","MA","MG","MS","MT","PA","PB","PE","PI","PR","RJ","RN","RO","RR","RS","SC","SE","SP","TO"]
      case 'AR': return ["SA","BA","DF","SL","ER","LR","SE","CC","SJ","CT","LP","MZ","MN","FM","NQ","RN","SF","TM","CH","TF","CN","CB","JY","SC"]
      case 'BO':
      case 'BL': return ["EB","CB","CQ","LP","PA","OR","PO","SC","TR"]
      case 'AU': return ["AC","NS","NT","QL","SA","TS","VI","WA","JB","X03~","AS"]
      case 'CA': return ["AB","BC","MB","NB","NL","NS","NT","NU","ON","PE","QC","SK","YT"]
      case 'CL': return ["AI","AN","AP","AR","AT","BI","CO","LI","LL","LR","MA","ML","RM","TA","VS"]
      case 'CO': return ["AM","AN","AR","AT","BL","BY","CL","CQ","CS","CA","CE","CH","CO","CU","GN","GV","HU","LG","MA","ME","NA","NS","PU","QD","RI","ST","SA","SU","TO","VC","VP","VD","X01~"]
      case 'CR': return ["AL","CA","GU","HE","LI","PU","SJ"]
      case 'DO': return ["NC","AZ","BR","BH","DA","DU","EP","SE","ES","IN","AL","RO","VE","MT","MC","PN","PV","PP","SC","SM","CR","JU","PM","SZ","ST","SR","VA","MN","MP","HM","JO","SD"]
      case 'EC': return ["AZ","BO","CR","OR","ES","CN","GY","CB","IM","LJ","MN","NA","EO","PC","LR","MS","SD","SE","TU","SU","GA","CT","PA","ZC"]
      case 'ES': return ["VC","CM","AN","CL","CT","EX","PV","GA","CE","CN","AR","LO","MD","ML","MU","NA","AS","PM","CB"]
      case 'FR': return ["AI","AS","CS","HC","AL","AP","HA","AM","AH","AN","AG","AB","AD","AV","BD","CV","CL","CT","CM","CH","CZ","CO","CA","CR","DD","DB","DM","EU","EL","FI","GA","HG","GE","GI","HE","IV","IN","IL","IS","JU","LD","LC","LR","HL","LA","LT","LO","LG","LZ","ML","MH","MR","HM","MY","MM","MS","MB","MO","NI","NO","OI","OR","PC","PD","PA","HP","PO","BR","HR","RH","HN","SL","ST","SV","HS","VP","SM","SE","YV","DS","SO","TA","TG","VR","VC","VD","VN","HV","VG","YO","TB","ES","HD","SS","VM","VO","GF","GP","MQ","RE","YT"]
      case 'GT': return ["AV","BV","CM","CQ","ES","GU","HU","IZ","JA","JU","PE","PR","QC","QZ","RE","SA","SM","SO","SR","SU","TO","ZA"]
      case 'HN': return ["AT","CH","CL","CM","CP","CR","EP","FM","GD","IB","IN","LE","LP","OC","OL","SB","VA","YO"]
      case 'IT': return ["AG","AL","AN","AO","AP","AQ","AR","AT","AV","BA","BG","BI","BL","BN","BO","BR","BS","BT","BZ","CA","CB","CE","CH","CI","CL","CN","CO","CR","CS","CT","CZ","EN","FC","FE","FG","FI","FM","FR","GE","GO","GR","IM","IS","KR","LC","LE","LI","LO","LT","LU","MB","MC","ME","MI","MN","MO","MS","MT","NA","NO","NU","OG","OR","OT","PA","PC","PD","PE","PG","PI","PN","PO","PR","PT","PU","PV","PZ","RA","RC","RE","RG","RI","RM","RN","RO","SA","SI","SO","SP","SR","SS","SV","TA","TE","TN","TO","TP","TR","TS","TV","UD","VA","VB","VC","VE","VI","VR","VS","VT","VV"]
      case 'IL': return ["HD","HA","JM","HM","TA","HZ"]
      case 'JP': return ["HK","AO","IW","MG","AK","YT","FS","IB","TC","GM","ST","CH","TK","KN","NI","TY","IS","FI","YN","NN","GF","SZ","AI","ME","SH","KY","OS","HG","NR","WK","TT","SM","OY","HS","YC","TS","KG","EH","KC","FO","SG","NS","KM","OT","MZ","KS","ON"]
      case 'MY': return ["JH","KH","KN","ME","NS","PH","PG","PK","PL","SL","TE","SA","SK","KL","LA","PJ"]
      case 'MX': return ["AG","BN","BS","CM","CH","CP","CA","CL","DF","DU","GR","GJ","HI","JA","MX","MC","MR","NA","NL","OA","PU","QE","QR","SI","SL","SO","TB","TM","TL","VE","X01~","YU","ZA"]
      case 'PA': return ["BC","CC","CL","CH","DR","HE","LS","SB","VR","EM","SB","NB"]
      case 'PE': return ["AM","AN","AP","AR","AY","CJ","CL","CS","HC","HV","IC","JU","LL","LB","LR","LO","MD","MQ","PA","PI","PU","SM","TA","TU","UC"]
      case 'PR': return ["X00~"]
      case 'PT': return ["AV","BE","BR","BA","CB","CO","EV","FA","GU","LE","LI","PA","PO","SA","SE","VC","VR", "VI","AC","MA"]
      case 'PY': return ["CN","SP","CR","GU","CG","CZ","IT","MI","PG","AA","CE","NE","AM","CY","PH","AG","BQ","AS"]
      case 'GB':
      case 'UK': return ["AS","AR","AB","AY","AG","AN","ND","AM","BN","BW","FO","BA","BE","XB","BF","BJ","BG","BI","BU","BL","BY","BM","BB","EF","BH","BX","BT","BP","BC","BV","BZ","BS","BR","CM","CP","CB","CG","CR","CQ","CZ","CF","MU","CX","CC","CL","CU","CN","CI","CO","CT","CA","CY","CS","CW","DA","DB","DI","DE","DO","DN","DG","DC","DU","DS","DW","LD","DY","DH","EG","EA","EB","ED","EL","WI","EF","ER","EY","EX","ES","FK","FE","FI","FL","GH","GG","GC","GR","GD","HL","HA","HV","HK","HE","HD","HI","HF","HU","HP","HT","HR","HY","IS","IW","IT","IC","KC","KE","KH","KK","KT","KN","LA","LT","LC","LS","LE","LW","LI","LV","LM","IT","LR","LB","LU","MN","MB","MW","MF","MK","EB","MM","ME","MO","MT","MY","ED","NB","ND","NE","NU","NF","NG","NN","NL","NS","NW","NA","NP","NT","NI","NH","NO","NY","NM","OL","OM","MO","OX","PE","PK","PM","PL","PS","PO","PB","RC","RD","RT","RB","RG","RF","RU","RH","RL","ZW","SA","BO","SF","SE","SJ","SV","MS","SP","SK","ZF","ZL","SL","SD","SI","SM","SS","SR","SB","SO","ZG","ZH","SU","ST","ZT","SX","SW","SN","SQ","TM","TK","TR","TB","TF","TD","TH","VG","WR","WB","WD","WF","WN","WL","WK","WZ","WH","WV","WW","BK","WO","WC","HL","WT","WX","WE","WS","YK","SH"]
      case 'US': return ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","HI","IA","ID","IL","IN","KS","KY","LA","MA","MD","ME","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VA","VT","WA","WI","WV","WY"]
      case 'UY': return ["AR","CA","CL","CO","DU","FD","FS","LA","MA","MO","PA","RN","RO","RV","SA","SJ","SO","TA","TT"]
      default: return []
    }
  } else return []
}
