<template>
  <div data-anima="top" class="collapse-cupom-container">
    <div
      class="collapse-cupom"
      v-b-toggle.collapse-cupom
      @click="cupom_active = !cupom_active"
    >
      <div class="text-cupom">
        <img src="@/assets/img/icon/cupom.svg" />
        <span>{{
          cupom_aplicado
            ? $t("checkout.cupom.header_aplicado")
            : $t("checkout.cupom.header_add")
        }}</span>
      </div>
      <img src="@/assets/img/icon/arrow-fill.svg" />
    </div>

    <b-collapse id="collapse-cupom" v-model="collapse" data-anima="top">
      <b-row>
        <b-col cols="12">
          <b-form-group label="" label-for="code_cupom" v-if="!cupom_aplicado">
            <b-form-input
              data-test="code_cupom"
              type="text"
              id="code_cupom"
              v-model="cupom"
              :placeholder="$t('checkout.cupom.insira')"
              name="code_cupom"
              autocomplete="off"
              :state="!cupom_error"
              :disabled="cupom_aplicado"
            ></b-form-input>
            <b-form-invalid-feedback v-if="cupom_error === true" :state="!cupom_error">
              {{ $t("checkout.cupom.invalido") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="cupom_error === 'convercao'" :state="!cupom_error">
              {{ $t("checkout.cupom.conversao") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="cupom_aplicado">
            <p class="cupom_aplicado">
              {{ $t("checkout.cupom.cupom") }}
              <span>{{ cupom.toUpperCase() }}</span>
              {{ $t("checkout.cupom.aplicado") }}
            </p>
          </div>
        </b-col>

        <b-col cols="12" class="mb-4">
          <div id="aplicar-cupom">
            <b-button
              variant="secondary"
              class="btn-cupom"
              v-if="!cupom_aplicado"
              :disabled="!cupom"
              @click="checkCupom"
              >{{ $t("checkout.cupom.aplicar") }}</b-button
            >
          </div>
          <b-tooltip
            v-if="!cupom"
            target="aplicar-cupom"
            :title="$t('checkout.cupom.insira')"
          />

          <div>
            <div v-if="cupom_aplicado && cupomResponse.available">
              <b-badge variant="success">
                {{
                  cupomResponse.available > 10
                    ? `${$t("checkout.cupom.restam")} ${
                        cupomResponse.available
                      } ${$t("checkout.cupom.disponiveis")}`
                    : `${$t("checkout.cupom.acabando")} ${$t(
                        "checkout.cupom.restam"
                      )} ${cupomResponse.available} ${$t(
                        "checkout.cupom.disponiveis"
                      )}`
                }}
              </b-badge>
            </div>

            <a
              href="#"
              class="remover-cupom"
              v-if="cupom_aplicado"
              @click.prevent="removeCupom"
              >{{ $t("checkout.cupom.remover") }}</a
            >
          </div>
        </b-col>
      </b-row>
      <div class="timer" v-if="cupomResponse.due_date">
        <span>O tempo está passando</span>
        <CountDown :validade="cupomResponse.due_date" />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import CountDown from "./CountDown.vue";

import CouponService from "@/services/resources/CouponService";
const serviceCupom = CouponService.build();

export default {
  name: "Cupom",

  data() {
    return {
      id_product: 0,
      cupomResponse: {
        amount: 0,
        available: 0,
        due_date: null,
      },
      desconto_amount: 0,
      cupom: "",
      cupom_active: false,
      cupom_aplicado: false,
      cupom_error: false,
      collapse: false
    };
  },
  components: {
    CountDown,
  },
  props: {
    cupom_checkout: {
      type: String,
      default: "",
    },
    amount: {
      type: Number,
      default: 0,
    },
    product_name: {
      type: String,
      default: "",
    },
    product_id: {
      type: Number,
      default: 0,
    },
    country:{
      type: String,
      default: "BR",
    },
    countryGlobalSettings:{
      type: String,
      default: "BR",
    },
    discountAmount: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  mounted() {
    if (this.cupom_checkout && this.discountAmount) this.setCupom();
  },
  methods: {
    setCupom() {
      this.cupom = this.cupom_checkout ? this.cupom_checkout : this.cupom;
      this.desconto_amount = this.discountAmount ?? 0;
      this.$parent.cupom_valid = true
      this.$parent.cupom_checkout = this.cupom
      this.cupom_aplicado = true
      this.cupom_error = false

      if (!this.cupom_active) {
        const collapse = this.$el.querySelector(".collapse-cupom");
        if (collapse) {
          this.collapse = true;
        }
      }
      this.$parent.removePreload();
      this.$parent.changeParcelamentoCartao();
    },
    removeCupom() {
      this.$parent.preload = true
      this.$parent.amount_cupom = 0;
      this.$parent.amount -= this.desconto_amount
      this.$parent.amount += this.amount
      this.$parent.cupom_valid = false
      this.$parent.cupom_checkout = ""

      this.$parent.paypal.amount = this.$parent.paypal.original_amount

      if (
        this.$parent.data.has_shipping_fee &&
        this.$parent.shipping.old_amount
      ) {
        // produto fisico
        this.$parent.shipping.old_amount = this.amount;
        this.$parent.shipping.amount = 0;
      }

      this.cupom = "";
      this.cupom_aplicado = false;

      this.cupomResponse = {
        amount: 0,
        available: 0,
      };

      if (this.cupom_active) {
        const collapse = this.$el.querySelector(".collapse-cupom");
        if (collapse) {
          collapse.click();
        }
      }

      if (this.$parent.data.has_shipping_fee) {
        if (this.$parent.data.billing_shipping_same_address) {
          this.$parent.getCEP("charge");
        } else {
          this.$parent.getCEP("shipping");
        }
      }

      setTimeout(() => {
        this.$parent.removePreload();
        this.$parent.changeParcelamentoCartao();
      }, 500);
    },
    checkCupom(amountCheckout = 0) {
      const amountFixo = this.amount || amountCheckout;
      if (amountFixo) {
        this.$parent.preload = true;
        this.cupom = this.cupom_checkout ? this.cupom_checkout : this.cupom;

        const withOffer = this.$route.params.hash ? `/offer/${this.$route.params.hash}` : "";

        let data = {
          id: `check/${this.cupom.toUpperCase()}/${
            this.product_id || this.id_product
          }${withOffer}`,
        };

        let country = localStorage.getItem("location") ?? this.country
        country = country === 'OUTROS' ? this.countryGlobalSettings : country
        data.id = data.id+'?country='+country

        serviceCupom
          .read(data)
          .then((response) => {
            if (response.amount) {
              this.$parent.original_amount = this.$parent.method === 'PAYPAL'
                ? this.$parent.paypal.original_amount
                : this.$parent.original_amount
              this.$parent.amount = this.$parent.original_amount
              this.$parent.amount -= amountFixo
              this.$parent.amount += response.amount
              this.$parent.amount_cupom = Math.abs(response.amount-this.$parent.product.amount);
              this.desconto_amount = response.amount
              this.$parent.discount_amount = response.amount;
              this.$parent.cupom_valid = true
              this.$parent.cupom_checkout = this.cupom
              this.cupom_aplicado = true
              this.cupom_error = false

              this.$parent.paypal.amount = response.paypal ? response.paypal.amount : response.amount

              if (this.$parent.data.has_shipping_fee) {
                this.$parent.original_amount += this.$parent.shipping.amount;
              }

              this.cupomResponse = response;

              this.$bvToast.toast(
                `${this.$t("checkout.cupom.header_aplicado")} ${this.$t(
                  "checkout.cupom.no_produto"
                )}: ${this.product_name.toUpperCase()}`,
                {
                  title: this.$t("checkout.cupom.cupom"),
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
            }
          })
          .catch((err) => {
            console.error(err)
            const input_cupom = this.$el.querySelector("#code_cupom");
            if (input_cupom) {
              input_cupom.focus();
            }

            this.cupom = "";
            this.cupom_error = true;
            this.$parent.cupom_valid = false;

            if(JSON.parse(err.response.data)[0] === "O cupom informado não possuí conversão configurada.") {
              this.cupom_error = 'convercao';
            }
          })
          .finally(() => {
            if (!this.cupom_active) {
              const collapse = this.$el.querySelector(".collapse-cupom");
              if (collapse) {
                collapse.click();
                this.collapse = true;
              }
            }
            this.$parent.removePreload();
            this.$parent.changeParcelamentoCartao();
          });
      }
    },
  },
};
</script>

<style scoped>
/* // cupom */
.collapse-cupom-container {
  padding: 0 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 40px auto 20px auto;
  transition: 0.3s;
}
@media screen and (max-width: 769px) {
  .collapse-cupom-container {
    margin: 20px auto;
  }
}
.collapse-cupom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0;
  outline: none !important;
}

.text-cupom {
  display: flex;
  align-items: center;
  gap: 10px;

  color: var(--text-input);
}
.collapse-cupom span {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
}
.btn-cupom {
  font-size: 14px;
  height: 50px;
  width: 100%;
}
#code_cupom {
  text-transform: uppercase;
}
#code_cupom::placeholder {
  text-transform: initial !important;
}
.remover-cupom {
  font-size: 13px;
}

.btn-cupom:disabled {
  background: #f7f7f7 !important;
  color: #333 !important;
}
.btn-cupom:disabled:hover {
  transform: none !important;
}
.aplicado-cupom {
  margin: 0;
  margin-bottom: 10px;
  color: #81858e;
  font-size: 13px;
}
.badge-success {
  padding: 10px 15px;
  text-align: left;
  white-space: normal;
  font-weight: 600;
  line-height: 1.5;
  background-color: var(--transparent);
  color: var(--main-color);
  margin-bottom: 10px;
}
.timer span {
  color: var(--text-color);
  font-size: 13px;
  /* font-weight: 600; */
  display: block;
}
</style>