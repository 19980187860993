<template>
  <b-tab
    id="OXXO"
    data-test="OXXO"
    title-link-class="tab-nav-icon OXXO"
    @click="$emit('click')"
  >
    <template v-slot:title>
      <font-awesome-icon icon="credit-card" class="icon" />
      OXXO
    </template>
  </b-tab>
</template>

<script>
export default {
  name: 'Oxxo'
}
</script>
