<template>
  <b-tab
    id="SAFETYPAY-CASH"
    data-test="SAFETYPAY-CASH"
    title-link-class="tab-nav-icon SAFETYPAY-CASH"
    @click="$emit('click')"
  >
    <template v-slot:title>
      <font-awesome-icon icon="credit-card" class="icon" />
      SAFETYPAY-CASH
    </template>
  </b-tab>
</template>

<script>
export default {
  name: 'SafetypayCash'
}
</script>
