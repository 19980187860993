<template>
  <span>
    <slot></slot>
    <vue-programmatic-invisible-google-recaptcha
      ref="invisibleRecaptcha1"
      :sitekey="getEnvCaptcha()"
      :elementId="'invisibleRecaptcha1'"
      :badgePosition="'left'"
      :showBadgeMobile="false"
      :showBadgeDesktop="false"
      @recaptcha-callback="recaptchaCallback"
    >
    </vue-programmatic-invisible-google-recaptcha>
  </span>
</template>

<script>
export default {
  data() {
    return {
      recaptchaToken: null,
    };
  },
  methods: {
    getEnvCaptcha() {
      return process.env.VUE_KEY_CAPTCHA;
    },
    resetCaptcha() {
      this.$refs.invisibleRecaptcha1.reset();
    },
    setCaptcha() {
      this.$refs.invisibleRecaptcha1.execute();
    },
    recaptchaCallback(recaptchaToken) {
      this.$emit("changeVerify", recaptchaToken);
    },
  },
};
</script>