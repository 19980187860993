<template>
  <!-- Order Bump Card -->
  <section class="order-bump-card" :style="stylesheet">
    <!-- Header -->
    <header @click="$emit('clicked')">
      <!-- Checkbox header -->
      <b-form-checkbox
        @change="$emit('clicked')"
        @click="$emit('clicked')"
        v-model="item.checkbox"
      >
        
        <span v-if="custom && custom.ob_subtitle" class="ml-2 info-value">{{ custom.ob_subtitle }}</span>
        <span v-else class="ml-2 info-value">{{ $t('checkout.pagamento.bump.yes_i_want') }}</span>
      </b-form-checkbox>
      <!-- Trial message -->
      <span v-if="hasTrial" class="info-value">
        {{ trialMessage }}
      </span>
      <!-- Product amount -->
      <span v-else class="info-value">
        {{ `${currentCurrency} ${formatMoney(amount)}` }}
      </span>
    </header>
    <!-- Body -->
    <main class="body">
      <section class="body-content">
        <!-- Image, type and detail button -->
        <section class="left__side">
          <section v-if="!item.images.length" class="no-image"></section>
          <img
            v-else
            :src="item.images[0].path"
            alt="logo"
            height="100%"
            width="100%"
          />
          <section class="type-and-details">
            <span class="item-info">
              {{ getType(item.type) }}
            </span>
            <button
              class="item-details"
              @click.stop.prevent="details = !details"
              v-if="item.type !== 'TRANSACTION'"
            >
              {{ details ? $t('checkout.pagamento.bump.hide') : $t('checkout.pagamento.bump.details') }}
            </button>
          </section>
        </section>
        <!-- More product infos -->
        <section class="right__side" :class="`${item.type.toLowerCase()}`">
          <div class="bump-product-title">
            <h1 class="item-title">{{ item.name }}</h1>
            <b-tooltip           
          :target="tooltipTarget"
          :title="$t('checkout.venda_por_indicacao')"/>
            <span v-if="!isBumpSellerEqual" :id="tooltipTarget" class="different-seller"  @click="redirect">
              <font-awesome-icon
                    icon="star"                                
                  />
                  {{
                      item.seller.company
                        ? item.seller.company.fantasy_name ||
                          item.seller.company.name
                        : item.seller.name
                    }}
                </span>
          </div>
          <template v-if="hasTrial">
            <h3 class="info-value custom-color">
              {{ trialMessage }}
            </h3>
            <span class="trial-info">
              {{ trialMessageAlternative }}:
              {{ `${currentCurrency} ${formatMoney(amount)}` }}/ {{ $t('checkout.pagamento.bump.month') }}
            </span>
          </template>
          <transition name="fade" mode="out-in">
            <section class="hide-details" v-if="!details" key="hide-details">
              <span v-if="!hasTrial && !hasCustomCharges" class="info-value custom-color">{{
                `${currentCurrency} ${formatMoney(amount)}`
              }}</span>
              <section class="charges" :opened="details" v-if="hasCustomCharges && !exceptionSellerId">
                <p v-for="charge in customCharges" :key="charge.id" class="w-100 d-flex justify-content-between">
                  <span>{{ charge.sequence }}ª {{ $t('checkout.different_amount_text.charge') }}</span>
                  <span>{{ `${currentCurrency} ${formatMoney(charge.amount)}` }}</span>
                </p>
                <p class="w-100 d-flex justify-content-between">
                  <span class="text-wrap pr-2">{{ $t('checkout.different_amount_text.other_charges') }}</span>
                  <span>{{ `${currentCurrency} ${formatMoney(item.amount)}` }}</span>
                </p>
              </section>
              <section class="item-info my-2 text-wrap text-left trial-info" v-if="hasShippingFee && item.shipping_fee_is_recurring === 0">
                {{ $t('checkout.recurring_shipping.isNotRecurring') }}
              </section>
            </section>
            <section class="show-details" v-else key="show-details">
              <section class="details" v-if="!hasCustomCharges">
                <span class="info-value-400">{{ $t('checkout.pagamento.bump.product') }}</span>
                <span class="info-value-400">
                  {{ `${currentCurrency} ${formatMoney(amount)}` }}
                </span>
              </section>
              <section class="charges" :opened="details" v-else>
                <p v-for="charge in customCharges" :key="charge.id" class="w-100 d-flex justify-content-between">
                  <span>{{ charge.sequence }}ª {{ $t('checkout.different_amount_text.charge') }}</span>
                  <span>{{ `${currentCurrency} ${formatMoney(charge.amount)}` }}</span>
                </p>
                <p class="w-100 d-flex justify-content-between">
                  <span class="text-wrap pr-2">{{ $t('checkout.different_amount_text.other_charges') }}</span>
                  <span>{{ `${currentCurrency} ${formatMoney(item.amount)}` }}</span>
                </p>
              </section>
              <section class="details" v-if="hasShippingFee">
                <span class="info-value-400">Frete</span>
                <span class="info-value-400">
                  {{
                    shipping.price
                      ? `${currentCurrency} ${formatMoney(parseFloat(shipping.price))}`
                      : shipping.price === 0 
                        ? $t('checkout.pagamento.bump.free')
                        : $t('checkout.pagamento.bump.to_calculate')
                  }}
                </span>
              </section>
            </section>
          </transition>
          <span class="item-description" v-if="showDescription">
            {{ item.description }}
          </span>
        </section>
      </section>
      <div
        v-if="
          item.checkbox && data.zipcode && hasShippingFee && !isFixedShipping
        "
        data-anima="top"
        class="opcoes-frete"
      >
        <!-- Loading -->
        <b-spinner v-if="shippingLoading" label="Loading..."></b-spinner>

        <b-form-group
          :label="$t('checkout.pagamento.bump.select_shipping')"
          v-if="shippingOptions.length && hasShippingFee && !shippingLoading"
          v-slot="{ ariaDescribedby }"
          class="frete-remove-dot"
        >
          <div
            v-for="frete in shippingOptions"
            :key="frete.id"
            class="frete-item"
          >
            <b-form-radio
              v-model="shipping"
              name="frete"
              :aria-describedby="ariaDescribedby"
              :value="frete"
            >
              <b-container class="shipping-content">
                <img :src="frete.company.picture" />
                <section class="shipping-content">
                  <span class="shipping-name">
                    {{ frete.name }}
                  </span>
                  <span v-if="frete.delivery_range">
                    {{ frete.delivery_range.min }} {{ $t('checkout.pagamento.bump.at') }}
                    {{ frete.delivery_range.max }} {{ $t('checkout.pagamento.bump.working_days') }}
                  </span>
                  <span class="frete-valor">
                    {{ `${currentCurrency} ${formatMoney(parseFloat(frete.price))}` }}
                  </span>
                </section>
              </b-container>
            </b-form-radio>
          </div>
        </b-form-group>
      </div>
    </main>
  </section>
</template>

<script>
// Utils
import { EventBus } from "@/main.js";
// Mixins
import Money from "@/mixins/money";
// Services
import ShippingService from "@/services/resources/ShippingService";
const shippingService = ShippingService.build();

export default {
  mixins: [Money],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    method: {
      type: String,
      required: true,
      default: () => "",
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    custom: {
      type: Object,
      required: false,
      default: () => {},
    },
    currentCurrency: {
      type: String,
      required: false,
      default: () => '',
    },
    productSellerId: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      details: false,
      // Shipping
      shipping: {},
      shippingOptions: [],
      shippingLoading: false,
    };
  },
  computed: {
    exceptionSellerId() {
      if(process.env.CUSTOM_CHARGES_EXCEPTION) {
        const ids = JSON.parse(process.env.CUSTOM_CHARGES_EXCEPTION)
        return ids.some(x => parseInt(x) === parseInt(this.item.seller.id))
      }
      return false
    },
    hasShippingFee() {
      return !!this.item.has_shipping_fee;
    },
    hasTrial() {
      return !!this.item.trial;
    },
    trialMessage() {
      if (this.item.trial === 1) {
        return `${this.$t('checkout.pagamento.bump.free_for')} ${this.item.trial} ${this.$t('checkout.pagamento.bump.day')}.`;
      }
      return `${this.$t('checkout.pagamento.bump.free_for')} ${this.item.trial} ${this.$t('checkout.pagamento.bump.day')}s.`;
    },
    trialMessageAlternative() {
      if (this.item.trial === 1) {
        return `${this.$t('checkout.pagamento.bump.after_trial_period')} ${this.item.trial} ${this.$t('checkout.pagamento.bump.day')}`;
      }
      return `${this.$t('checkout.pagamento.bump.after_trial_period')} ${this.item.trial} ${this.$t('checkout.pagamento.bump.day')}s`;
    },
    showDescription() {
      return this.custom ? this.custom.ob_description : true;
    },
    stylesheet() {
      return {
        "--background-header": this.custom
          ? this.custom.ob_background_color.color
          : "#ff0c37",
        "--background-body": this.custom
          ? this.custom.ob_background_color.background
          : "rgba(255, 12, 55, 0.1)",
        "--font-color-body": this.custom
          ? this.custom.ob_background_color.color
          : "#ff0c37",
        "--border-body-type": this.custom
          ? this.custom.ob_border !== "off"
            ? this.custom.ob_border === "pontilhada"
              ? `dashed`
              : `solid`
            : "none"
          : "none",
        "--border-body-color": this.custom
          ? this.custom.ob_background_color.color
          : "",
        "--border-body-width": this.custom
          ? `${this.custom.ob_border_px}px`
          : "0",
      };
    },
    isFixedShipping() {
      return this.item.type_shipping_fee === "FIXED";
    },
    amount() {
      return this.hasCustomCharges ? this.customCharges[0].amount : this.item.amount;
    },
    /**
     * @returns this method returns if product has custom charges
     */
     hasCustomCharges() {
      if (this.item.custom_charges) return !!this.item.custom_charges.length
      return false;
    },
    /**
     * @returns this method returns product custom charges
     */
    customCharges() {
      if (!this.hasCustomCharges) return null;
      return this.item.custom_charges;
    },
    isBumpSellerEqual() {
      return this.item.seller.id === this.productSellerId;
    },
    tooltipTarget() {      
      return `seller-${this.item.id}`;
    }
  },
  methods: {
    getType(type = "") {
      switch (type) {
        case "SUBSCRIPTION":
          return this.$t('checkout.pagamento.bump.signature');
        default:
          return this.$t('checkout.pagamento.bump.transaction');
      }
    },

    async getShippingOptions() {
      if (this.hasShippingFee && this.isFixedShipping) {
        this.shipping = {
          price: this.item.amount_fixed_shipping_fee,
        };
        return;
      }
      if (this.item.checkbox && this.hasShippingFee && this.data.zipcode) {
        this.shippingLoading = true;
        await shippingService
          .create(
            {
              shipping_address_zip_code: this.data.zipcode.replace(/[-]/g, ""),
            },
            `/calculate/${this.item.id}`
          )
          .then((res) => {
            this.shippingOptions = res;
            this.shipping = res[0] ? res[0] : [];
          })
          .finally(() => {
            this.shippingLoading = false;
          });
      }
    },

    redirect() {
      if (this.item.links.length && this.item.links[0].url) {
        let url = this.item.links[0].url;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url;
        }
      window.open(url, '_blank');
      } else {
        this.$bvToast.toast(
                ` ${this.$t("checkout.link_vendedor_nao_encontrado")}`,
                {
                  title: this.$t("checkout.link_vendedor_nao_encontrado"),
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
      }
    }
  },
  mounted() {
    if (this.item.checkbox) this.getShippingOptions();
    if (this.isFixedShipping)
      this.shipping = { price: this.item.amount_fixed_shipping_fee };

    EventBus.$on("hasCep", () => {
      this.getShippingOptions();
    });
  },
  watch: {
    "item.checkbox": {
      deep: true,
      handler(value) {
        if (value) this.getShippingOptions();
      },
    },
    shipping: {
      handler(value) {
        const { id = null, name = null, price = null } = value;
        let shipping = {};
        shipping.shipping_selected = JSON.stringify(value);
        if (id) shipping.shipping_service_id = id;
        if (name) shipping.shipping_service_name = name;
        if (price !== null) shipping.shipping_amount = price;
        this.$emit("selected-shipping", {
            ...this.item,
            shipping
          }
        );
      },
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.order-bump-card {
  width: 100%;
  height: 100%;
  margin: 20px 0;

  header {
    width: 100%;
    height: 50px;
    background: var(--background-header);
    border-radius: 5px 5px 0px 0px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .custom-control,
    .custom-control-input {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::after {
      filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg) !important;
    }
  }

  .body {
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    padding: 30px 35px;
    background: var(--background-body);
    display: flex;
    flex-direction: column;
    user-select: none;
    border: var(--border-body-width);
    border-style: var(--border-body-type);
    border-color: var(--border-body-color);
    border-top: none;

    .body-content {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 30px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      .left__side,
      .right__side,
      .hide-details,
      .show-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        gap: 10px;

        &:not(.show-details) {
          gap: 15px;
        }
      }

      .left__side {
        max-width: 100px;

        .no-image {
          width: 100px;
          height: 120px;
          border-radius: 5px;
          background: var(--bg);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(6.9px);
          -webkit-backdrop-filter: blur(6.9px);
        }

        img {
          object-fit: contain;
          border-radius: 5px;
          width: 100px;
        }

        .item-info {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          color: var(--font-color-body);
        }

        .item-details {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          text-decoration-line: underline;
          color: var(--text-color);
          background: transparent;
          border: none;

          padding: 0;
          transition: ease-in-out 200ms;
          cursor: pointer;

          &:hover {
            transform: scale3d(1.1, 1.1, 1.1);
          }
        }

        .type-and-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
          }

        @media (max-width: 1000px) {
          & {
            max-width: 100%;
          }
          .item-info {
            font-size: 15px;
          }
          .item-details {
            font-size: 15px;
            text-align: right;
          }

          .type-and-details {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }
        }

      }

      .right__side {
        .item-title {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: var(--text-color);
        }

        .trial-info {
          background: var(--background-body);
          border-radius: 5px;
          padding: 10px 15px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: var(--font-color-body);
        }

        .item-description {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;
          color: #81858e;
          text-align: justify;
        }

        .details {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .info-value {
    color: white;
    letter-spacing: 0.2px;
    font-size: 14px;
    font-weight: 600;
  }

  .info-value-400 {
    color: var(--text-color);
    letter-spacing: 0.2px;
    font-size: 14px;
    font-weight: 400;
  }

  .custom-color {
    color: var(--font-color-body);
    font-weight: 600;
  }

  .custom-control-label {
    width: 100%;
  }

  .shipping-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .frete-valor {
    color: var(--main-color);
    font-weight: 600;
    text-align: right;
  }

  .frete-item {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: var(--main-color) !important;
      background-color: var(--main-color) !important;
    }
  }

  .charges {
    width: 100%;
    border: 1px var(--background-header) solid;
    border-radius: 5px;
    
    margin: 10px 0;
    padding: 15px;
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50px;
    overflow-y: hidden;
    
    &[opened] {
      height: 100%;
    }
    p {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      
      color: #81858E;
      font: 12px 400 'Montserrat';
      line-height: 15px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.bump-product-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .different-seller{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    color: var(--font-color-body);
    background-color: var(--background-body);
    border-radius: 5px;
    padding: 10px;
    &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        transition: ease-in-out 300ms;
        background-color: var(--background-header);
        color: white;
        cursor: pointer;
      }
  }
}
</style>