import { render, staticRenderFns } from "./Locales.vue?vue&type=template&id=35ab9e39&scoped=true"
import script from "./Locales.vue?vue&type=script&lang=js"
export * from "./Locales.vue?vue&type=script&lang=js"
import style0 from "./Locales.vue?vue&type=style&index=0&id=35ab9e39&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ab9e39",
  null
  
)

export default component.exports